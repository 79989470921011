import { AppScreenContainer } from '../../components/ui/global';
import { TableList } from '../../components/ui/views';
import { useGetInvitesQuery } from '../../store/api';

export const InvitesScreen = () => {
  const { data: invites } = useGetInvitesQuery();

  return (
    <AppScreenContainer title='Invites'>
      <TableList
        items={invites || []}
        display={[ 'email' ]}
        headings={{ email: 'Email' }}
      />
    </AppScreenContainer>
  );
};
